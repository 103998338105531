import { CommonButton, DatePicker, Modal } from '@chocolate-soup-inc/cs-frontend-components';
import clsx from 'clsx';
import format from 'date-fns/format';
import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';
import { TCustomsMappingType } from '../../../entities/giftPackages/queries';
import { TShipmentType } from '../../../entities/shipments/queries';
import { usePrintShipmentLabelMutation } from '../../../generated/graphql';
import styles from './PrintMultipleLabelModal.module.scss';
import { ShipmentCustomsTable } from './ShipmentCustomsTable';

type TPrintMultipleLabelModalProps = {
  shipments: TShipmentType[];
  onCancel: () => void;
  onSuccess: () => void;
  defaultPackages: TCustomsMappingType;
};

export const PrintMultipleLabelModal = (props: TPrintMultipleLabelModalProps) => {
  const { shipments, onCancel, onSuccess, defaultPackages } = props;

  const [shipmentsToUpdate, setShipmentsToUpdate] = useState<TShipmentType[]>(shipments);
  const [actualShippingDate, setActualShippingDate] = useState<Date>();

  const headline = useMemo(() => {
    return `Print Label for ${shipments.length} Shipments`;
  }, [shipments.length]);

  const supportingText = useMemo(() => {
    return `Set the actual shipping date and customs data for the ${shipments.length} shipments`;
  }, [shipments.length]);

  const [printLabel] = usePrintShipmentLabelMutation();

  const onSubmit = useCallback(() => {
    if (!actualShippingDate) return toast.error('There no Shipping Date set.');
    return Promise.all(
      shipmentsToUpdate.map((shipment) => {
        const input = {
          actualShippingDate: format(actualShippingDate, 'yyyy-MM-dd'),
          customsData: shipment.customsData,
        };
        return printLabel({
          variables: {
            id: shipment.id,
            companyId: shipment.companyId,
            version: shipment._version,
            input,
          },
        });
      }),
    )
      .then(() => {
        if (onSuccess) onSuccess();
      })
      .catch((error) => {
        console.error(serializeError(error));
        toast.error('There was an error printing the shipment labels.');
      });
  }, [onSuccess, printLabel, actualShippingDate, shipmentsToUpdate]);

  const bottomComponent = useCallback(() => {
    if (!shipments) return <></>;
    return (
      <div className={styles.topComponent}>
        <CommonButton
          className={clsx(styles.addBtn)}
          leadingIcon='close'
          label='Cancel'
          onClick={onCancel}
          variant='outlined'
        />
        <CommonButton
          className={clsx(styles.addBtn)}
          leadingIcon='check'
          label='Print Label'
          onClick={onSubmit}
          variant='filled'
        />
      </div>
    );
  }, [shipments, onCancel, onSubmit]);

  return (
    <Modal
      headline={headline}
      size='large'
      supportingText={supportingText}
      closeModal={onCancel}
      showCustomButton={true}
      customButton={bottomComponent}
    >
      <DatePicker
        label='Shipping Date'
        name='actualShippingDate'
        className={clsx(styles.datePicker)}
        value={actualShippingDate}
        onChange={(v) => setActualShippingDate(v as Date)}
      />
      <div className={clsx(styles.shipmentsContainer)}>
        {shipmentsToUpdate.map((s) => {
          return (
            <ShipmentCustomsTable
              key={s.id}
              shipment={s}
              setShipmentsToUpdate={setShipmentsToUpdate}
              defaultPackages={defaultPackages}
            />
          );
        })}
      </div>
    </Modal>
  );
};
