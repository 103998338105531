import { TProduct } from '../../generated/graphql';
import { TCuratedProduct } from './queries';
import _ from 'lodash';

export const pickProductsMapDefaultValues = (
  products: TProduct[],
  extraFields: (keyof TCuratedProduct)[] = [],
): TCuratedProduct[] => {
  return products.map((p) => _.pick(p, ['value', 'hsCode', 'quantity', 'description', 'country', ...extraFields]));
};
